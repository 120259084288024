'use client';
import styles from '@/app/ui/components/homepage/styles.module.scss';
import Link from 'next/link';
import React from 'react';
import { useRouter } from 'next/navigation';
import { handleSetGenderCookie } from '@/app/ui/components/tracking/setGenderCookie';

const HeroActionLink = ({
  url,
  content,
  gender,
  domain,
}: {
  url: string;
  content: string;
  gender: string;
  domain: string;
}) => {
  const router = useRouter();
  return (
    <Link
      href={url}
      className={styles.heroButton}
      onClick={() => {
        handleSetGenderCookie(domain, gender);
        router.refresh();
      }}>
      {content}
    </Link>
  );
};
export default HeroActionLink;
