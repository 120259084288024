import React from 'react';

type HorizontalLineProps = {
  className?: string;
  backgroundColor?: string;
  height?: string;
};

const HorizontalLine = (props: HorizontalLineProps): JSX.Element => {
  const { className = '', backgroundColor = '#ccc', height = '1px' } = props;
  return (
    <div
      className={`${className} w-100`}
      style={{
        backgroundColor,
        height,
      }}
    />
  );
};

export default HorizontalLine;
