'use client';
import styles from '@/app/ui/components/subheader/searchbar.module.scss';
import React, { useState } from 'react';
import clsx from 'clsx';
import useSearchSubmitHandler from '@/app/helpers/hooks/useSearchSubmitHandler';

export function SearchForm(props: { placeholder: string }) {
  const searchFormRef = React.createRef<HTMLFormElement>();
  const [searchTerm, setSearchTerm] = useState('');
  const handleSubmit = useSearchSubmitHandler(searchTerm);

  return (
    <div className="position-relative">
      <form ref={searchFormRef} onSubmit={handleSubmit} autoFocus={true} autoComplete="off" className={styles.search}>
        <input
          type="search"
          id={'searchTerm'}
          name={'searchTerm'}
          enterKeyHint="search"
          autoFocus={false}
          placeholder={props.placeholder}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
        <button
          onClick={() => {
            setSearchTerm('');
            const inputElement = searchFormRef.current?.querySelector('#searchTerm') as HTMLInputElement; // Set focus to the input field
            inputElement?.focus();
          }}
          className={`${clsx(styles.clearButton, searchTerm ? 'd-block' : 'd-none')}`}
          type="button"
          aria-label="Clear button"
        />
        <button className={styles.searchButton} type="submit" aria-label="Search submit button" />
      </form>
    </div>
  );
}
