'use client';
import { useEffect, useRef } from 'react';
import { trackSSTEvent } from '@/app/utils/tracking';
import useCmp from '@/app/ui/hooks/useCmp';

type T = {
  eventName: string;
  data: object | null;
};

const VisibilityTracker = ({ eventName, data }: T) => {
  const ref = useRef<HTMLDivElement>(null);
  const consents = useCmp('');
  const haveConsents = Object.keys(consents).length > 0;

  useEffect(() => {
    if (!haveConsents) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          trackSSTEvent(eventName, data);
          observer.disconnect();
        }
      },
      { threshold: 0.5 }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [haveConsents]);

  return <div ref={ref} />;
};

export default VisibilityTracker;
