'use client';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import Image from 'next/image';
import { DeviceType } from '@mybestbrands/types';
import clsx from 'clsx';

type Props = {
  children: React.ReactNode[];
  deviceType?: DeviceType;
  fiveItems?: boolean;
  threeItems?: boolean;
};

const Slider = ({ children, deviceType, fiveItems, threeItems }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [leftBtn, showLeft] = useState(false);
  const [rightBtn, showRight] = useState(false);
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      const overflow = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
      showRight(overflow > 0);
    }
    initialRender ? setInitialRender(false) : null;
  }, [children]);

  const scrollListener = useCallback(() => {
    if (scrollRef.current === null) return;

    const totalWidth = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
    const scrollAmount = scrollRef.current.scrollLeft || 0;

    if (scrollAmount === 0) showLeft(false);
    if (scrollAmount > 0) showLeft(true);
    if (scrollAmount === totalWidth) showRight(false);
    if (scrollAmount < totalWidth) showRight(true);
  }, []);

  // Attach the scroll listener to the div
  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.addEventListener('scroll', scrollListener);
    }
  }, [scrollListener]);

  const handleButtonScroll = (direction: 'L' | 'R') => {
    if (scrollRef && scrollRef.current) {
      const scrollAmount = direction === 'R' ? 450 : -450;
      const endPosition = scrollRef.current.scrollLeft + scrollAmount;
      scrollRef.current.scrollTo({ left: endPosition, behavior: 'smooth' });
    }
  };

  const centerItemsClass = () => {
    // Center items if there are less than 4 children and no right button - means all items are visible
    // If it is desktop we want to center
    // or !inital render - inital render is set to false after we check if is scroll so we don't have flickers in UI

    return children.length < 4 && !rightBtn && (deviceType === DeviceType.DESKTOP || !initialRender)
      ? styles.centerItems
      : null;
  };

  return (
    <div className={'position-relative'}>
      <div
        ref={scrollRef}
        className={clsx(threeItems ? styles.smallerButton : styles.horizontalScroll, centerItemsClass())}>
        {leftBtn && (
          <button
            className={styles.btnLeft + '  justify-content-center align-items-center'}
            onClick={() => handleButtonScroll('L')}>
            <Image height={14} width={8} src={'/intstatic/icons/black-arrow-left.svg'} alt={'arrow left'} />
          </button>
        )}
        {[...children].map((child, index) => (
          <div
            key={index + 'q'}
            className={fiveItems ? styles.childFive : threeItems ? styles.childThree : styles.child}>
            {child}
          </div>
        ))}
        {rightBtn && (
          <button
            className={styles.btnRight + '  justify-content-center align-items-center'}
            onClick={() => handleButtonScroll('R')}>
            <Image height={14} width={8} src={'/intstatic/icons/black-arrow-right.svg'} alt={'arrow right'} />
          </button>
        )}
      </div>
    </div>
  );
};

export default Slider;
