'use client';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { DeviceType, FavoriteCategory } from '@mybestbrands/types';
import FavCategories from '@/app/ui/components/homepage/favCategories';
import { COOKIE_NAMES, Gender, GENDER } from '@/app/constants';
import { setCookie } from 'cookies-next/lib';
import { useTranslation } from 'next-i18next';

type PopularCategoriesProps = {
  title?: string;
  favoriteCategories?: FavoriteCategory[];
  gender: string;
  showGender?: boolean;
  domain: string;
  deviceType: DeviceType;
};

const TabWidget = ({ title, domain, favoriteCategories, showGender, gender, deviceType }: PopularCategoriesProps) => {
  const [newGender, setGender] = useState(gender);
  const { t } = useTranslation('navigation');

  if (!favoriteCategories) return null;

  function onGenderClick(gender: Gender) {
    const domainWithoutSubdomain = '.' + domain.split('.').slice(-2).join('.');

    if (gender) {
      setCookie(COOKIE_NAMES.GENDER, gender, {
        path: '/',
        domain: domainWithoutSubdomain,
        maxAge: 60 * 60 * 24 * 365,
      });
    }
    setGender(gender);
  }

  return (
    <>
      <div data-testid="tab-widget">
        <div className={styles['title']}>{title}</div>
        {showGender && (
          <div className="row justify-content-md-center mb-3">
            <div className="col-6 col-md-2">
              <button
                onClick={() => onGenderClick(GENDER.WOMAN)}
                className={`${
                  newGender === GENDER.WOMAN ? 'btn-black-hover-light' : 'btn-white-hover-black'
                } btn  full-width button-gender`}
                type="button">
                {t(GENDER.WOMAN.toUpperCase())}
              </button>
            </div>
            <div className="col-6 col-md-2">
              <button
                onClick={() => onGenderClick(GENDER.MAN)}
                className={`${
                  newGender === GENDER.MAN ? 'btn-black-hover-light' : 'btn-white-hover-black'
                } btn  full-width button-gender`}
                type="button">
                {t(GENDER.MAN.toUpperCase())}
              </button>
            </div>
          </div>
        )}
        <FavCategories favoriteCategories={favoriteCategories} gender={newGender} deviceType={deviceType} />
      </div>
    </>
  );
};
export default TabWidget;
