'use client';
import Link from 'next/link';
import React from 'react';
import styles from './styles.module.scss';

import Image from 'next/image';
import { DeviceType, FavoriteCategory, FavoriteCategoryHomeLiving, Image as ImageType } from '@mybestbrands/types';
import Slider from '@/app/ui/components/slider';
import HorizontalLine from '@/app/ui/components/horizontalline';

type PopularCategoriesProps = {
  title?: string;
  favoriteCategories?: FavoriteCategory[] | FavoriteCategoryHomeLiving[];
  gender: string;
  deviceType: DeviceType;
};

const genderMapping: { [key: string]: string } = {
  male: 'Man',
  female: 'Woman',
};

const FavCategories = ({ title, favoriteCategories, gender, deviceType }: PopularCategoriesProps) => {
  if (!favoriteCategories) return null;

  const genderKey = genderMapping[gender] || '';

  const elements = favoriteCategories.map((c: any) => {
    const linkUrl = (c[`link${genderKey}Url` as keyof FavoriteCategory] as string) || c.linkUrl;
    const title = (c[`title${genderKey}` as keyof FavoriteCategory] as string) || c.title;
    const image = (c[`categoryImage${genderKey}` as keyof FavoriteCategory] as ImageType) || c.categoryImage;
    const linkText = (c[`linkText${genderKey}` as keyof FavoriteCategory] as string) || c.linkText;

    return (
      <div key={title} className={styles['favorite-category-item']}>
        <Link href={linkUrl}>
          <Image
            src={image.mediaItemUrl}
            alt={image.altText}
            width={307}
            height={213}
            sizes="100vw"
            style={{ width: '100%', height: 'auto', aspectRatio: '307/213' }}
            className={'mb-3'}
          />
          {title && <div className={styles.link2}>{title}</div>}
          <div className={'d-flex justify-content-center'}>
            <div className={styles.link3}>{linkText}</div>
          </div>
        </Link>
      </div>
    );
  });

  return (
    <>
      <div data-testid="favorite-categories">
        <div className={styles['title']}>{title}</div>
        <Slider deviceType={deviceType}>{elements}</Slider>
      </div>
      <HorizontalLine className={'my-5'} />
    </>
  );
};
export default FavCategories;
