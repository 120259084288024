'use client';
import { Editor } from '@mybestbrands/types';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import styles from './styles.module.scss';

type Props = {
  editor: Editor;
  isCard?: boolean;
};

const Editor = ({ editor, isCard }: Props) => {
  const { t } = useTranslation(['common']);
  if (!editor) return null;
  return (
    <div className={'d-flex gap-2 align-items-center'}>
      <Image
        src={editor.avatar.url}
        alt={editor.nickname}
        width={44}
        height={44}
        sizes="100vw"
        className={styles.editorImg}
      />
      <i className={isCard ? '' : 'd-flex gap-1'}>
        <div className={styles.editor}>
          <p style={{ marginBottom: '0.31rem' }}>
            {t('common:SELECTED_BY')} {editor.nickname}
          </p>
          <p>Fashion Editor</p>
        </div>
      </i>
    </div>
  );
};

export default Editor;
