'use client';
import { setCookie } from 'cookies-next';
import { COOKIE_NAMES } from '@/app/constants';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

export function getDomainWithoutSubdomain(domain: string) {
  if (domain.startsWith('localhost:')) {
    return undefined;
  }
  return '.' + domain.split('.').slice(-2).join('.');
}

export function handleSetGenderCookie(domain: string, gender: string) {
  setCookie(COOKIE_NAMES.GENDER, gender, {
    path: '/',
    domain: getDomainWithoutSubdomain(domain),
    maxAge: 60 * 60 * 24 * 365,
  });
}

const SetGenderCookie = ({ gender, domain }: { gender?: string; domain: string }) => {
  const router = useRouter();

  useEffect(() => {
    if (!gender) {
      return;
    }

    handleSetGenderCookie(domain, gender);
    router.refresh();
  }, [router, gender, domain]);

  return null;
};

export default SetGenderCookie;
